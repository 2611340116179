import React, { Fragment } from "react"
import styled from "@emotion/styled"
import info from "../images/info.jpg"
import map from "../images/map2.png"

const Info = () => {
  return (
    <Fragment>
      <InfoContainer>
        <ImageContainer>
          <img
            src={info}
            alt="informations sur le restaurant hara kiri ramen paris 75010"
            loading="lazy"
          ></img>
        </ImageContainer>
        <RightContainer>
          <MapContainer>
            {/* <GoogleMap /> */}
            <Map
              src={map}
              alt="localisation du restaurant Hara Kiti"
              loading="lazy"
            />
          </MapContainer>
          <InfoTextContainer>
            <Left>
              <TopLeft>
                <Title>NOTRE ADRESSE</Title>
                <Adress
                  href="http://maps.google.com/?q=Hara-kiri Ramen"
                  target="_blank"
                >
                  82 rue du faubourg Saint-Denis<br></br>75010 PARIS
                </Adress>
                <Metros>M4 – Chateau d'eau, Gare de l'Est</Metros>
                <Metros>M5 – Gare de l'Est</Metros>
                <Metros>M7 – Gare de l'Est</Metros>
              </TopLeft>
              <BottomLeft>
                <Title>CONTACT</Title>
                <ContactText>01.42.46.37.48</ContactText>
                <ContactText>contact@harakiriramen.fr</ContactText>
              </BottomLeft>
            </Left>

            <Right>
              <TopRight>
                <Title>HORAIRES D'OUVERTURE</Title>
                <DivText>
                  <p>Mardi – Jeudi</p>12h – 14h30 / 19h30 – 22h30
                </DivText>
                <DivText>
                  <p>Vendredi</p>12h – 15h / 19h30 – 23h
                </DivText>
                <DivText>
                  <p>Samedi</p>12h – 15h / 19h30 – 23h
                </DivText>
                <DivText>
                  <p>Dimanche</p>12h30 – 15h30 / 19h30 – 22h
                </DivText>
              </TopRight>
              <BottomRight>
                Cet établissement ne prend pas de réservations
              </BottomRight>
            </Right>
          </InfoTextContainer>
        </RightContainer>
      </InfoContainer>
    </Fragment>
  )
}

const InfoContainer = styled.div`
  height: 100vh;
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 640px) {
    height: auto;
    /* margin-top: 100px; */
    flex-wrap: wrap;
  }
`

const ImageContainer = styled.div`
  width: 40%;
  overflow: hidden;

  @media screen and (max-width: 640px) {
    height: 50vh;
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const RightContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media screen and (max-width: 640px) {
    /* height: 50vh; */
    width: 100%;
  }
`

const MapContainer = styled.div`
  height: 50%;
  overflow: hidden;

  @media screen and (max-width: 640px) {
    height: 50vh;
    width: 100%;
  }
`
const InfoTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 50%;
  width: 100%;
  padding: 6% 4%;
  justify-content: space-between;
  text-align: center;
  position: relative;

  @media screen and (max-width: 640px) {
    height: auto;
    width: 100%;
  }
`

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #e41408;
`

const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  @media screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 40px;
  }
`

const TopLeft = styled.div`
  @media screen and (max-width: 640px) {
    margin-bottom: 40px;
  }
`
const BottomLeft = styled.div``

const Adress = styled.a`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000;
`
const ContactText = styled.p`
  color: #000;
  font-size: 15px;
`

const Metros = styled.div`
  font-size: 15px;
`

const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
`

const TopRight = styled.div`
  @media screen and (max-width: 640px) {
    margin-bottom: 40px;
  }
`
const BottomRight = styled.p`
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 40px;
`

const DivText = styled.div`
  font-size: 15px;
  margin-bottom: 10px;
`

const Map = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

export default Info
